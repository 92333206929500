import Container from "react-bootstrap/Container";
import styled from "styled-components";

export const FrequencesStyles = styled(Container)`
max-width: 100%;
  .nota-falta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .data-filtercustom {
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    background-color: #f5f5f5;
    border: 3px solid #ccc;
    padding: 6px;
    margin: 6px 0;
  }

  .title-filter {
    margin: 15px 0px 5px 5px;
  }

  .title-datas {
    display: flex;
    border-bottom: 2px solid #ccc;
  }

  .section-selectbox {
    margin-left: 4px;
  }

  .selectbox {
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
    border: 3px solid #ccc;
    padding: 6px;
    margin: 6px 0;
  }

  .filter-icon {
    margin-top: 18px;
  }

  .calendar-icon {
    margin-top: -3em;
    margin-right: 2em;
    font-size: 21px;
  }

  .close-icon {
    color: gray;
    transition: color 0.3s;
  }

  .close-icon:hover {
    color: red;
  }

  .nota-falta label {
    margin-right: 5px;
  }

  .nota-falta input[type="checkbox"] {
    margin-top: 3px;
    margin-right: 8px;
  }
  .selectionOp {
    width: 20px;
    height: 20px;
  }

  .icon-title {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    border-bottom: 2px solid #e3e3e3;
  }

  .align-ava {
    display: flex;
    margin-bottom: 0.5em;
  }

  .title-icon {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    margin-top: 0.8rem;
  }

  .mt-3 {
    font-weight: 700;
  }

  .table {
    th {
      text-align: center;
      border: none;
    }

    .sticky-row {
      position: sticky;
      top: 0;
      background-color: #ffffff; /* Cor de fundo opcional para a linha fixa */
    }

    th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        background-color: #f2f2f2;
      }

    .form-control {
      text-align: center;
      background-color: white;
      border-color: #b2b2b2;
      font-weight: 200;
      color: #7e7f81;
      font-size: 14px;
      width: 70%;
    }

    #barra-pesquisa {
      background-color: #ffffff;
      text-align: initial;
      margin: 0px;
      padding: 12px 12px;
    }

    .search-container {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: -1em;
    }

    .search-icon {
      position: absolute;
      right: 10px; /* Ajuste a posição do ícone conforme necessário */
      top: 50%;
      transform: translateY(-50%);
      color: #666;
      cursor: pointer;
    }

    profile-student {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    #img-radius {
      border-radius: 50%;
      margin-left: 1em;
      margin-right: 1em;
    }

    .frequence-header {
      background-color: #faf9f8;
      padding: 5px 20px;
      border-radius: 12px;
      border-top: 0.2em solid #f5f5f5;
      border-left: 0.4em solid #f5f5f5;
      border-bottom: 0.4em solid #f5f5f5;
    }

    .frequence-cell {
      text-align: center; /* Centraliza o conteúdo horizontalmente */
    }

    .frequence-title-date {
      font-size: 13px;
      margin-bottom: 4px;
      font-weight: 600;
    }

    .frequence-title-hour {
      font-size: 10px;
      margin-bottom: 4px;
      font-weight: 600;
    }

    .frequence-subtitle {
      color: #5b636b;
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: -5px;
      place-content: space-around;
    }
    

    tbody td {
      text-align: center;
      vertical-align: middle;
      padding: 5px 10px 5px 10px;
    }

    .table-color {
      background-color: #faf9f8;
    }

    .column-fr{
      padding-left: 20px;
      align-self: center;
    }
    .column-av{
      line-height: 15px;
    }


    .aluno-cell {
      width: 325px;
      display: flex;
      align-items: center;
      font-size: 12px;
      background-color: #f5f5f5;
      text-aling: start;
      text-align: left;
    }

    .cellNote-color {
      background-color: #ffff;
    }

    .search-cell {
      width: 17%;
      border-bottom: 1em solid #f5f5f5;
      border-top: 0.2em solid #f5f5f5;
      background-color: #f5f5f5;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
  }

  .filter {
    position: relative;
    z-index: 9999;
  }

  .filter-tab {
    position: absolute;
    top: 2.2rem;
    right: 0;
    width: 50px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
  }

  .filter-tab.open {
    right: 200px; /* Largura da guia lateral */
  }

  .filter-tab i {
    font-size: 20px; /* Tamanho do ícone */
  }

  .filter-tab-content {
    z-index: 214;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    color: #444;
    width: 382px;
    height: 450px;
    padding: 10px;
    transition: right 0.3s;
    display: none;
    border-radius: 0.375rem;
    margin: 10px;
    -webkit-box-shadow: 0px 0px 7px 3px rgba(206, 212, 218, 1);
    -moz-box-shadow: 0px 0px 7px 3px rgba(206, 212, 218, 1);
    box-shadow: 0px 0px 7px 3px rgba(206, 212, 218, 1);

    p {
      font-size: 18px;
      margin: 12px 8px 8px 6px;
      font-weight: 600;
    }
  }

  .filter-tab-content.open {
    right: 0;
    display: block;
  }

  .bottom-buttons {
    position: absolute;
    bottom: 10px; /* Define a distância a partir da parte inferior */
    left: 0;
    right: 0;
    text-align: center;
    left: 240px;
    margin: 0em 0em 0.5em 4em;

    button {
      background: white;
      font-size: 26px;
      width: 33px;
      border: 0px solid;
    }
  }

  .date-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
  }

  .date-cell {
    padding: 8px;
  }

  .search-button {
    margin-top: 1.4rem;
    background: #f5f5f5;
    border: 3px solid #ccc;
  }

  .date-container.open {
    display: flex;
  }

  .column-av {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 5px;

    .rangeGrade {
      color: #aaaaaa;
      font-weight: 500;
    }
  }
`;
