import * as microsoftTeams from "@microsoft/teams-js";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import Avatar from "react-avatar";
import { Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { FaCalendarAlt, FaFilter, FaTimes } from "react-icons/fa";

import UserProfileService, {
  UserProfile,
} from "../../services/UserProfile/UserProfile";
import GroupOfficeService, {
  ClassesGroupOffice,
} from "../../services/GroupOffice/GroupOfficeService";
import FrequenceService, {
  StudentsFrequence,
} from "../../services/Frequences/FrequencesService";
import { handleGetRealTime, handleIsValidDate, handleTimeOutDisableSaving, handleVerifyDates } from "../../utils/functions";
import { Loader } from "../../components/Loader";
import { FrequencesStyles } from "./styles";

function Frequences() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSavingMsg, setIsSavingMsg] = useState<string>("")
  const [userProfile, setUserProfile] = useState<UserProfile>();

  const [listClasses, setClasses] = useState<ClassesGroupOffice[]>([]);
  const [listStudentsFrequence, setStudentsFrequence] = useState<
    StudentsFrequence[]
  >([]);


  const [searchTerm, setSearchTerm] = useState("");
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [showFilterDate, setShowFilterDate] = useState(false);
  const [initialDateFilter, setInitialDateFilter] = useState<any>(undefined);
  const [finalDateFilter, setFinalDateFilter] = useState<any>(undefined);
  const [selectedOption, setSelectedOption] = useState(1);
  const [frequenceIdSelected, setFrequenceIdSelected] = useState("");
  const [newFrequence, setnewFrequence] = useState<boolean>(false);

  const [studentFrequence, setStudentFrequence] = useState<any[]>([]);
  const [currentDateTime, setCurrentDateTime] = useState<Date | null>(null);
  const [userContext, setUserContext] = useState<any>()

  useEffect(() => {
    getUserProfile();
    getClassesGroupOffice(true, false, undefined, undefined);
    getStudentsFrequences(true, false, undefined, undefined);
  }, []);


  useEffect(() => {
    const fetchCurrentDateTime = async () => {
      try {
        const hora = await handleGetRealTime();
        setCurrentDateTime(hora);
      } catch (error) {
        console.error('Erro ao obter a data e hora atual:', error);
      }
    };

    fetchCurrentDateTime();
  }, []);

  function handleSearchFilter() {
    const initialDate = initialDateFilter?.split(" ")[0];
    const finalDate = finalDateFilter?.split(" ")[0];

    const initialDateIsValid = handleIsValidDate(initialDate);
    const finalDateIsValid = handleIsValidDate(finalDate);

    if (!finalDateIsValid) {
      toast.error("Preencha a data final com um valor válido.")
    }

    if (!initialDateIsValid) {
      toast.error("Preencha a data inicial com um valor válido.")
    }

    if (initialDateFilter !== "" && finalDateFilter !== "" && handleVerifyDates(initialDate, finalDate)) {
      setLoading(true);
      getClassesGroupOffice(false, false, initialDateFilter, finalDateFilter);
      getStudentsFrequences(false, false, initialDateFilter, finalDateFilter);
    }
  }

  const toggleTab = () => {
    setIsTabOpen(!isTabOpen);
  };

  const getUserProfile = async () => {
    microsoftTeams.getContext(async (context) => {
      setUserContext(context)
      const userProfile = await UserProfileService.get(
        context?.tid,
        context?.userObjectId,
        context?.groupId
      );
      setUserProfile(userProfile);
    });
  };

  const getClassesGroupOffice = async (
    today?: boolean,
    week?: boolean,
    initialDate?: string,
    finalDate?: string,
    studentOfficeId?: string
  ) => {
    microsoftTeams.getContext(async (context) => {
      const classes = await GroupOfficeService.list(
        context?.tid,
        context?.groupId,
        today,
        week,
        initialDate,
        finalDate
      );
      setClasses(classes ?? []);
    });
  };

  const getStudentsFrequences = async (
    today?: boolean,
    week?: boolean,
    initialDate?: string,
    finalDate?: string
  ) => {

    microsoftTeams.getContext(async (context) => {
      const listStudentsFrequence = await FrequenceService.list(
        context?.tid,
        context?.groupId,
        today,
        week,
        initialDate,
        finalDate,
      );

      if (listStudentsFrequence && listStudentsFrequence?.length) {
        const uniqueStudent = listStudentsFrequence.find(student => student.studentOfficeId === context.userObjectId);

        // Apenas para estudantes 
        if (uniqueStudent) {
          setStudentFrequence([uniqueStudent])
        }
      }

      setFrequenceIdSelected("");
      setnewFrequence(false);
      setStudentsFrequence(listStudentsFrequence ?? []);
      setLoading(false);
    });
  };



  const insertStudentFrequence = (ultilNote: any, student: any) => {
    setIsSaving(true)
    setIsSavingMsg("Salvando")
    FrequenceService.insert({
      studentOfficeId: ultilNote.studentOfficeId,
      groupOfficeId: ultilNote.groupOfficeId,
      classDate: ultilNote.classDate,
      typeUser: Number(userProfile?.typeUser),
      classOfficeId: ultilNote.classOfficeId,
      studentName: student.studentName,
      studentEmail: student.studentEmail,
      actionUserEmail: userContext.loginHint
    })
      .then(result => {

        microsoftTeams.getContext((context) => {
          FrequenceService.list(
            context?.tid,
            context?.groupId,
            selectedOption === 1,
            selectedOption === 2,
            initialDateFilter,
            finalDateFilter,
          ).then(listResult => {
            setFrequenceIdSelected("");
            setnewFrequence(false);
            setStudentsFrequence(listResult ?? []);
            setIsSaving(false)
          })
            .catch(error => {
              toast.error("Erro ao listar alunos, tente novamente.")
              console.error("Erro ao listar alunos, tente novamente. Erro => ", error)
              setIsSaving(false)
              return;
            })
        });
        if (result) {
          toast.success("Frequência lançada com sucesso!");
          setIsSavingMsg("Carregando")
        }
        return;
      })
      .catch(error => {
        console.error("FREQUENCIA => Erro ao inserir frequência ERRO: ", error)
        toast.error(error?.response?.data?.errors[1]?.message);
        handleTimeOutDisableSaving(500, setIsSaving)
        return;
      });
  };

  const deleteStudentFrequence = (ultilNote: any) => {
    setIsSaving(true)
    setIsSavingMsg("Salvando")
    FrequenceService.delete(ultilNote.id, userContext.loginHint)
      .then(result => {
        microsoftTeams.getContext((context) => {
          FrequenceService.list(
            context?.tid,
            context?.groupId,
            selectedOption === 1,
            selectedOption === 2,
            initialDateFilter,
            finalDateFilter,
          ).then(listResult => {
            setFrequenceIdSelected("");
            setnewFrequence(false);
            setStudentsFrequence(listResult ?? []);
            setIsSaving(false)
          })
            .catch(error => {
              toast.error("Erro ao listar alunos, tente novamente.")
              console.error("Erro ao listar alunos, tente novamente. Erro => ", error)
              setIsSaving(false)
              return;
            })

        });

        if (result) {
          setIsSavingMsg("Carregando")
          toast.success("Frequência removida com sucesso!");
        }
        return;
      })
      .catch(error => {
        console.error("FREQUÊNCIA => Erro ao remover frequência ERRO: ", error)
        toast.error("Erro ao salvar informação, tente novamente.")
        handleTimeOutDisableSaving(500, setIsSaving)
        return;
      })
  };

  const handleFrequenceChange = async (
    studentFrequency: any,
    event: any,
    index: any,
    student: any
  ) => {
    setFrequenceIdSelected(index + "-" + studentFrequency.studentOfficeId);
    setnewFrequence(event.target.checked);

    if (studentFrequency.id == null) {
      insertStudentFrequence(studentFrequency, student);
    }
    else {
      deleteStudentFrequence(studentFrequency);
    }
  };

  const handleSelectChange = async (event: any) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedOption(selectedValue);

    const isOption1 = selectedValue === 1;
    const isOption2 = selectedValue === 2;
    const showFilter = selectedValue === 3;

    setLoading(!showFilter);

    if (selectedValue !== 3) {
      setInitialDateFilter(undefined);
      setFinalDateFilter(undefined);
    }
    if (showFilter) {
      setShowFilterDate(true);
    } else {
      setShowFilterDate(false);
      getClassesGroupOffice(isOption1, isOption2, undefined, undefined);
      getStudentsFrequences(isOption1, isOption2, undefined, undefined);
    }
  };

  const handlelDateChange = (event: any) => {
    if (event.target.name === "initialDate") {
      setInitialDateFilter(`${event.target.value}  00:00:00.000`);
    }
    if (event.target.name === "finalDate") {
      setFinalDateFilter(`${event.target.value}  00:00:00.000`);
    }
  };

  const filteredAlunos = listStudentsFrequence.filter((student) =>
    student.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGoBack = () => {
    setSearchTerm("");
  };

  if (loading && !isTabOpen) {
    // if (!loading) {
    return (
      <FrequencesStyles>
        <Loader />
      </FrequencesStyles>
    )
  }

  return (
    <FrequencesStyles>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            zIndex: "2147483648",
          },
        }}
      />
      <div className="icon-title">
        <div className="align-ava">
          <img
            src={require("../../assets/icons/iconAvaliacao.png")}
            alt="Ícone"
            className="title-icon"
          />
          <h4 className="mt-3">Frequências</h4>

          {/* Filtros */}
          <div
            className={`filter-tab ${isTabOpen ? "open" : ""}`}
            onClick={toggleTab}
          >
            <FaCalendarAlt className="calendar-icon" />
          </div>
          <div className={`filter-tab-content ${isTabOpen ? "open" : ""}`}>
            <div className="title-datas">
              <p>Filtros</p>
              <FaFilter className="filter-icon" />
            </div>
            <div className="title-filter">Intervalo de datas</div>
            <div className="section-selectbox">
              <select
                className="selectbox"
                onChange={handleSelectChange}
                value={selectedOption}
              >
                <option value="1">Hoje</option>
                <option value="2">Esta semana</option>
                <option value="3">Personalizado</option>
              </select>
            </div>
            <br />
            <div className={`date-container ${showFilterDate ? "open" : ""}`}>
              <div className="date-cell">
                <label>Data inicial:</label>
                <input
                  className="data-filtercustom"
                  onChange={(event) => {
                    handlelDateChange(event);
                  }}
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={initialDateFilter?.split(" ")[0] ?? "dd/mm/aaaa"}
                />
              </div>
              <div className="date-cell">
                <label>Data final:</label>
                <input
                  className="data-filtercustom"
                  onChange={(event) => {
                    handlelDateChange(event);
                  }}
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={finalDateFilter?.split(" ")[0] ?? "dd/mm/aaaa"}
                />
              </div>

              <Button
                className="search-button"
                variant="tertiary"
                onClick={handleSearchFilter}
              >
                <BsSearch />
              </Button>
            </div>
            <div className="bottom-buttons">
              <button onClick={toggleTab}>
                <FaTimes className="close-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {userProfile?.typeUserTitle === "Professor" && (
        <>
          <div className="table-responsive">
            {isSaving && (
              <div className="isSavingContainer">
                <Loader />
                <span>{isSavingMsg}</span>
              </div>
            )}
            {!loading && !isSaving &&
              filteredAlunos.length > 0 &&
              listClasses.length > 0 && (
                // Tabela
                <Table bordered hover responsive className="table">
                  <thead>
                    <tr>
                      <th className="search-cell">
                        <div className="search-container">
                          <Form.Control
                            type="text"
                            placeholder="Pesquisar estudantes"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            id="barra-pesquisa"
                            className="w-100"
                          />
                          <AiOutlineSearch className="search-icon" />
                        </div>
                      </th>
                      {listClasses.map((classes, index) => (
                        <th
                          className="frequence-header"
                          // key={"th-" + "-" + classes.id}
                          key={classes.id}
                          id="text-align-table"
                        >
                          <div className="frequence-cell">
                            <div className="frequence-title-date">
                              {classes.classDate}
                            </div>
                            <div className="frequence-title-hour">
                              {classes.classHour.replace(" 0m", "")}
                            </div>
                            <div className="frequence-subtitle">Falta</div>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAlunos.map((student) => (
                      <tr className="table-color" key={student.studentOfficeId}>
                        <td className="align-middle aluno-cell gap-2 px-2">
                          <Avatar name={student.studentName} round={true} size="35" color="#5b5fc7" maxInitials={2} textSizeRatio={2.9} />
                          {student.studentName}
                        </td>
                        {student.frequence.map((itemFrequency: any, index: any) => {
                          let combinedKey = index + "-" + student.studentOfficeId;
                          let dateFromBack = new Date(itemFrequency?.deadline);

                          return (
                            <td
                              key={itemFrequency?.classDate}
                              className="cellNote-color cell-falta"
                            >
                              <input
                                id={
                                  "check-" + combinedKey
                                }
                                type="checkbox"
                                className="selectionOp"
                                checked={frequenceIdSelected === combinedKey ? newFrequence : itemFrequency.id !== null}
                                onChange={(event) => {
                                  if ((dateFromBack < currentDateTime!) || !userProfile?.launchGrade) return;
                                  handleFrequenceChange(itemFrequency, event, index, student);
                                }}
                                disabled={(dateFromBack < currentDateTime!) || !userProfile?.launchGrade}
                              />
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

            {loading && (
              <div className="table-responsive">
                <Loader />
              </div>
            )}
          </div>

          {!loading && listClasses.length === 0 && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existem aulas para este período.
              </h6>
            </div>
          )}

          {!loading && filteredAlunos.length === 0 && searchTerm !== "" && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Nenhum aluno encontrado com as iniciais "{searchTerm}".
              </h6>
              <div>
                <button className="selectbox" onClick={handleGoBack}>
                  Voltar
                </button>
              </div>
            </div>
          )}

          {!loading && filteredAlunos.length === 0 && searchTerm === "" && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existem alunos para realizar lançamentos.
              </h6>
            </div>
          )}
        </>
      )}

      {userProfile?.typeUserTitle === "Aluno" && (
        <div className="table-responsive">
          {!loading && studentFrequence[0]?.frequence?.length !== 0 && (
            <Table bordered hover responsive className="table">
              <thead>
                <tr>

                  <th className="search-cell">
                    <div className="search-container">
                      {/* Aluno(a) */}
                    </div>
                  </th>

                  {listClasses.map((classes, index) => {
                    return (
                      <th
                        className="frequence-header"
                        key={classes.id}
                        id="text-align-table"
                      >
                        <div className="frequence-cell">
                          <div className="frequence-title-date">
                            {classes.classDate}
                          </div>
                          <div className="frequence-title-hour">
                            {classes.classHour.replace(" 0m", "")}
                          </div>
                          <div className="frequence-subtitle">Falta</div>
                        </div>
                      </th>
                    )
                  }
                  )}
                </tr>
              </thead>
              <tbody>
                {studentFrequence.map((student) => {
                  return (
                    <tr className="table-color" key={student?.studentOfficeId}>
                      <td className="align-middle aluno-cell gap-2 px-2">
                        <Avatar name={student.studentName} round={true} size="35" color="#5b5fc7" maxInitials={2} textSizeRatio={2.9} />
                        {student.studentName}
                      </td>
                      {student.frequence.map((value: any, index: any) => (
                        <td
                          key={value?.classDate}
                          className="cellNote-color cell-falta"
                        >
                          <input
                            disabled={true}
                            id={"check-" + index + "-" + student.studentOfficeId}
                            type="checkbox"
                            className="selectionOp"
                            checked={
                              frequenceIdSelected ===
                                index + "-" + student.studentOfficeId
                                ? newFrequence
                                : value.id != null
                                  ? true
                                  : false
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}

          {!loading && studentFrequence[0]?.frequence?.length === 0 && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existe frequência lançada para este período.
              </h6>
            </div>
          )}

          {loading && (
            <div className="table-responsive">
              <Loader />
            </div>
          )}
        </div>
      )}
    </FrequencesStyles>
  );
}
export default Frequences;
