import ApiService from "../ApiService";

export interface UserProfile {   
    typeUser: string | number | undefined; 
    typeUserTitle: string;
    launchGrade: boolean;
}



export default class UserProfileService {
  
    static async get(tenantId?: string, userOfficeId?: string, groupOfficeId?: string) {
      return ApiService.get<UserProfile>(
        `v1/UserProfile/${tenantId}/${userOfficeId}/${groupOfficeId}/VerifyProfile`
      );
    }
}
