import ApiService from "../ApiService";

export interface StudentsFrequence  {
    studentOfficeId: string;
    studentName: string;
    frequence: Array<Frequence>;
}

export interface Frequence  {
    id: string,
    studentOfficeId: string,
    groupOfficeId: string,
    classDate: string,
    classOfficeId: string,
    deadline?: string
}

export interface NewFrequence  {    
    studentOfficeId: string,
    groupOfficeId: string,
    classDate: string,
    typeUser: number,
    classOfficeId: string,
    studentName: string,
    studentEmail: string,
    actionUserEmail: string,
}

export interface UpdateFrequence  {
    id: string,
    studentOfficeId: string,
    groupOfficeId: string,
    classDate: string,
    typeUser: number,
    classOfficeId: string,
}

export default class FrequenceService {

    static async list(tenantId?: string, groupOfficeId?: string, today?: boolean, week?: boolean, initialDate?: string, finalDate?: string, studentOfficeId?: string) {

        let url = `v1/Frequence/${tenantId}/${groupOfficeId}/List`

        if(today)
            url =`${url}?today=${today}`; 

        if(week)
            url =`${url}?week=${week}`; 

        if(initialDate != null && finalDate != null)
            url =`${url}?initialDate=${initialDate}&finalDate=${finalDate}`; 
        
        if(studentOfficeId)
            url =`${url}?studentOfficeId=${studentOfficeId}`;                     
                
        return ApiService.get<StudentsFrequence[]>(url);
    }

    static async insert(body: NewFrequence) {        
        return ApiService.post(`v1/Frequence/Insert`, body);
    }

    static async update(body: UpdateFrequence) {     
        const id = body.id;           
        return ApiService.put(`v1/Frequence/${id}/Update`, body);
    }

    static async delete(id: string, actionUserEmail: string) {             
        return ApiService.delete(`v1/Frequence/${id}/${actionUserEmail}/Delete`);
      }

 }