import axios from "axios";
import toast from "react-hot-toast";

export const handleIsValidDate = (dateString: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) return false;

  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (year < 1000 || year > 9999 || month === 0 || month > 12) return false;

  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
  const monthsWith30Days = [4, 6, 9, 11];

  if (monthsWith31Days.includes(month)) {
    return day > 0 && day <= 31;
  } else if (monthsWith30Days.includes(month)) {
    return day > 0 && day <= 30;
  } else {
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      return day > 0 && day <= 29;
    } else {
      return day > 0 && day <= 28;
    }
  }
};

export const handleVerifyDates = (start: string, end: string) => {
  if (start && end) {
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);

    if (startDateObj > endDateObj) {
      toast.error('A data final não pode ser anterior à data inicial.');
      return false;
    } else {
      return true
    }
  }
};

export const handleTimeOutDisableSaving = (timeout = 500, setState: any) => {
  setTimeout(() => {
    setState(false)
  }, timeout);
}

export const handleGetRealTime = async (): Promise<Date> => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/timezone/America/Sao_Paulo');
    const data = response.data;
    return new Date(data.datetime);
  } catch (error) {
    console.error('Erro ao obter a hora atual: ', error);
    return new Date();
  }
};
