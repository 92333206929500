import { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import Avatar from "react-avatar";
import { AiOutlineSearch } from "react-icons/ai";

import UserProfileService, {
  UserProfile,
} from "../../services/UserProfile/UserProfile";
import EvaluationService, {
  Evaluation,
} from "../../services/Evaluations/EvaluationService";
import EvaluationGradeService, {
  StudentsEvaluation,
} from "../../services/EvaliationsGrades/EvaluationGradeService";
import { Loader } from "../../components/Loader";
import { handleGetRealTime, handleTimeOutDisableSaving } from "../../utils/functions";
import { GradesStyles } from "./styles";

function Grades() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [listStudents, setListStudents] = useState<StudentsEvaluation[]>([]);
  const [listEvaluation, setEvaluations] = useState<Evaluation[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [evaluationGradeIdSelected, setEvaluationGradeIdSelected] = useState("");
  const [newNoteEvaluation, setnewNoteEvaluation] = useState("");
  const [studentGrades, setStudentGrades] = useState<any[]>([]);
  const [currentDateTime, setCurrentDateTime] = useState<Date | null>(null);
  const [needChangeSend, setNeedChangeSend] = useState<boolean>(false);
  const [userContext, setUserContext] = useState<any>()


  useEffect(() => {
    getUserProfile();
    getEvaluations();
    getStudentsNotes();
  }, []);

  useEffect(() => {
    const fetchCurrentDateTime = async () => {
      try {
        const hora = await handleGetRealTime();
        setCurrentDateTime(hora);
      } catch (error) {
        console.error('Erro ao obter a data e hora atual:', error);
      }
    };

    fetchCurrentDateTime();
  }, []);

  const getUserProfile = async () => {
    microsoftTeams.getContext(async (context) => {
      setUserContext(context)
      const userProfile = await UserProfileService.get(
        context?.tid,
        context?.userObjectId,
        context?.groupId
      );
      setUserProfile(userProfile);

      if (userProfile?.typeUserTitle === "Aluno") {
        const student = await EvaluationGradeService.list(
          context?.tid,
          context?.groupId,
          context?.userObjectId
        );
        setStudentGrades(student);
      }
    });
  };

  const getEvaluations = async () => {
    microsoftTeams.getContext(async (context) => {
      const evaluations = await EvaluationService.list(
        context?.tid,
        context?.groupId
      );
      setEvaluations(evaluations ?? []);
    });
  };

  const getStudentsNotes = async () => {
    microsoftTeams.getContext(async (context) => {
      const listStudents = await EvaluationGradeService.list(
        context?.tid,
        context?.groupId
      );
      setListStudents(listStudents ?? []);
      setEvaluationGradeIdSelected("");
      setnewNoteEvaluation("");
      setLoading(false);
    });
  };

  const insertStudentNote = (insertEvaluationGrade: any) => {
    setIsSaving(true)

    EvaluationGradeService.insert(insertEvaluationGrade)
      .then((result: any) => {
        if (result) {
          const newList = listStudents?.map(student => {
            if (student.studentOfficeId === result.studentOfficeId) {
              const indexToChange = student?.evaluationGrade?.findIndex(evaluation => evaluation.evaluationId === result.evaluationId)

              student.evaluationGrade[indexToChange].id = result.id;
              student.evaluationGrade[indexToChange].grade = result.grade;
              student.evaluationGrade[0].grade = result.averageGrade;
            }
            return student;
          })

          setListStudents(newList ?? []);
          setEvaluationGradeIdSelected("");
          setnewNoteEvaluation("");

          toast.success("Nota lançada com sucesso!");
        }
        setIsSaving(false)
        return;
      })
      .catch(error => {
        console.error("Avaliação Insert => Erro ao inserir nota de avaliação. ERRO: ", error)
        toast.error(error?.response?.data?.errors[1]?.message);
        handleTimeOutDisableSaving(500, setIsSaving)
        return;
      });
  };


  const updateStudentNote = (updateEvaluationGrade: any) => {
    setIsSaving(true)

    EvaluationGradeService.update(updateEvaluationGrade)
      .then((result: any) => {
        if (result) {
          const newList = listStudents?.map(student => {
            if (student.studentOfficeId === result.studentOfficeId) {
              const indexToChange = student?.evaluationGrade?.findIndex(evaluation => evaluation.id === result.id)
              student.evaluationGrade[indexToChange].grade = result.grade;
              student.evaluationGrade[0].grade = result.averageGrade;
            }
            return student;
          })
          setListStudents(newList ?? []);
          setEvaluationGradeIdSelected("");
          setnewNoteEvaluation("");

          toast.success("Nota lançada com sucesso!");
        }
        setIsSaving(false)
        return;
      })
      .catch(error => {
        console.error("Avaliação Update => Erro ao atualizar nota de avaliação. ERRO: ", error)
        toast.error(error?.response?.data?.errors[1]?.message);
        handleTimeOutDisableSaving(500, setIsSaving)
        return;
      });
  };

  const handleDeleteEvaluationGrade = async (
    deleteTutorialGradeData: any,
    message: string) => {

    setIsSaving(true)
    EvaluationGradeService.delete(deleteTutorialGradeData.id, userContext.loginHint)
      .then((result: any) => {
        if (result) {
          // O resultado da exclusão é um boolean TRUE OR FALSE
          // Se for true estou modificando o objeto (parâmetro)
          // e realizando uma atualização na lista de estudantes
          const newList = listStudents?.map(student => {
            if (student.studentOfficeId === deleteTutorialGradeData.studentOfficeId) {
              const indexToChange = student?.evaluationGrade?.findIndex(tutorial => tutorial.id === deleteTutorialGradeData.id)

              student.evaluationGrade[indexToChange].grade = null;
              student.evaluationGrade[indexToChange].id = null;
              student.evaluationGrade[0].grade = result.result.averageGrade;
            }
            return student;
          })

          setListStudents(newList ?? []);
          setEvaluationGradeIdSelected("");
          setnewNoteEvaluation("");

          toast.success(`${message} excluída com sucesso!`);
        }
        setIsSaving(false)
        return;
      })
      .catch(error => {
        toast.error("Erro ao excluir nota da avaliação, tente novamente.")
        console.error("Erro ao excluir nota da avaliação, tente novamente. Erro => ", error)
        setIsSaving(false)
        return;
      })
  }

  const handleNoteChange = async (
    evaluationGrade: any,
    newNote: string,
    evaluationGradeId: string
  ) => {
    // Only professor can change note
    if (userProfile?.typeUserTitle !== "Professor") return;

    if (newNote !== evaluationGrade.grade) {
      // Valida se existem apenas 2 casas após a vírgula
      const match2Digits = newNote?.trim()?.replace(",", ".")?.match(/^\d*\.?\d{0,2}$/);

      if (match2Digits) {
        const numericNewNote = parseFloat(newNote.replace(",", "."));
        const numericNewNoteEvaluationState = parseFloat(newNoteEvaluation.replace(",", "."));
        const numericMaxGrade = parseFloat(
          evaluationGrade.maximumGrade.replace(",", ".")
        );

        if (numericNewNote < 0.0 || numericNewNoteEvaluationState < 0.0) {
          toast.error("A nota não pode ser negativa, ajustamos o valor para 0,00.");
          setnewNoteEvaluation("0,00")
          evaluationGrade.grade = "0,00";
          return false;
        } else if (numericNewNote > numericMaxGrade || numericNewNoteEvaluationState > numericMaxGrade) {
          toast.error("A nota lançada é maior que a nota máxima, ajustamos o valor para 10,00.");
          setnewNoteEvaluation("10,00")
          evaluationGrade.grade = "10,00";
          return false;
        } else {
          newNote = newNote.replace(".", ",");
          evaluationGrade.grade = newNote;
          setEvaluationGradeIdSelected(evaluationGradeId);
          setnewNoteEvaluation(newNote);
        }
      }
    }
  };
  const handleNotaChangeSend = async (
    evaluationGrade: any,
    student: any,
  ) => {
    if (userProfile?.typeUserTitle !== "Professor") return;

    // se o usuário deixar o campo nota vazio
    if (evaluationGrade.grade === "") {
      if (evaluationGrade.id) {
        await handleDeleteEvaluationGrade(evaluationGrade, "Nota")
        return;
      }
    }

    if (evaluationGrade.grade === null) {
      return;
    }

    evaluationGrade.typeUser = Number(userProfile?.typeUser);
    evaluationGrade.studentName = student.studentName;
    evaluationGrade.studentEmail = student.studentEmail;
    evaluationGrade.actionUserEmail = userContext.loginHint;

    if (evaluationGrade.id == null) {
      await insertStudentNote(evaluationGrade);
    }

    else {
      await updateStudentNote(evaluationGrade);
    }
  };

  const filteredAlunos = listStudents.filter((student) =>
    student.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGoBack = () => {
    setSearchTerm("");
  };

  const handleRenderSubtitle = (name: string) => {
    if (!name || name === "") return;
    name = name?.toLocaleLowerCase();

    if (name.includes("frequência")) {
      return <span>0 - 100%</span>
    }
    if (name.includes("tutor")) {
      return <span>0 - 1,1</span>
    }

    if (name.includes("avaliação")) {
      return <span>0 - 10</span>
    }

    if (name.includes("prova")) {
      return <span>0 - 10</span>
    }
  }

  if (loading) {
    return (
      <GradesStyles>
        <div className="table-responsive">
          <Loader />
        </div>
      </GradesStyles>
    )
  }

  return (
    <GradesStyles>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            zIndex: "2147483648",
          },
        }}
      />
      <div className="icon-title">
        <div className="align-ava">
          <img
            src={require("../../assets/icons/iconAvaliacao.png")}
            alt="Ícone"
            className="title-icon"
          />
          <h4 className="mt-3">Avaliações</h4>
        </div>
      </div>
      {userProfile?.typeUserTitle === "Professor" && (
        <>
          <div className="table-responsive">
            {isSaving && (
              <div className="isSavingContainer">
                <Loader />
                <span>Salvando</span>
              </div>
            )}
            {!loading && !isSaving && filteredAlunos.length > 0 && (
              <Table bordered hover responsive className="table">
                <thead>
                  <tr>
                    <th className="search-cell">
                      <div className="search-container">
                        <Form.Control
                          type="text"
                          placeholder="Pesquisar estudantes"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          id="barra-pesquisa"
                          className="w-100"
                        />
                        <AiOutlineSearch className="search-icon" />
                      </div>
                    </th>
                    {listEvaluation.map((evaluation, index) => (
                      <th
                        className="prova-header"
                        key={"th-" + index + "-" + evaluation.id}
                        id="text-align-table"
                      >
                        <div className="prova-cell">
                          <div className="prova-title">
                            {evaluation.name.toUpperCase()}
                          </div>
                          <div className="prova-subtitle">
                            {handleRenderSubtitle(evaluation.name)}
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredAlunos.map((student, index) => (
                    <tr className="table-color" key={index}>
                      <td className="align-middle aluno-cell gap-2 px-2">
                        <Avatar name={student.studentName} round={true} size="35" color="#5b5fc7" maxInitials={2} textSizeRatio={2.9} />
                        {student.studentName}
                      </td>
                      {student.evaluationGrade.map((value: any, index: any) => {
                        let isDisabled = value?.id === "00000000-0000-0000-0000-000000000000"
                        let dateFromBack = new Date(value?.deadline);
                        let valueInput = evaluationGradeIdSelected === value.id &&
                          value.id != null
                          ? newNoteEvaluation
                          : value.grade != null
                            ? value.grade
                            : ""

                        return (
                          <td
                            key={"td-" + index + "-" + student.studentOfficeId}
                            className="cellNote-color"
                          >
                            <input
                              id={"text-" + index + "-" + student.studentOfficeId}
                              disabled={isDisabled || (dateFromBack < currentDateTime!) || !userProfile.launchGrade}
                              value={valueInput}
                              onChange={(e: { target: { value: string } }) => {
                                if (isDisabled || (dateFromBack < currentDateTime!) || !userProfile.launchGrade) return;
                                setNeedChangeSend(true);
                                handleNoteChange(value, e.target.value, value.id);
                              }}
                              onBlur={() => {
                                if (isDisabled || !needChangeSend || (dateFromBack < currentDateTime!) || !userProfile.launchGrade) return;
                                handleNotaChangeSend(value, student)
                              }}
                              onFocus={() => setNeedChangeSend(false)}
                              className="form-control"
                            />
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {loading && (
              <div className="table-responsive">
                <Loader />
              </div>
            )}
          </div>

          {/* Cenário vazio */}
          {!loading && filteredAlunos.length === 0 && searchTerm !== "" && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Nenhum aluno encontrado com as iniciais "{searchTerm}".
              </h6>
              <div>
                <button className="selectbox" onClick={handleGoBack}>
                  Voltar
                </button>
              </div>
            </div>
          )}

          {!loading && filteredAlunos.length === 0 && searchTerm === "" && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existem alunos para realizar lançamentos.
              </h6>
            </div>
          )}
        </>
      )}

      {userProfile?.typeUserTitle === "Aluno" && (
        <div className="table-responsive">
          {!loading && studentGrades.length > 0 && (
            <Table bordered hover responsive className="table">
              <thead>
                <tr>
                  <th className="search-cell">
                    <div className="search-container">Aluno(a)</div>
                  </th>
                  {listEvaluation.map((evaluation, index) => (
                    <th
                      className="prova-header"
                      key={"th-" + index + "-" + evaluation.id}
                      id="text-align-table"
                    >
                      <div className="prova-cell">
                        <div className="prova-title">
                          {evaluation.name.toUpperCase()}
                        </div>
                        <div className="prova-subtitle">
                          {handleRenderSubtitle(evaluation.name)}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {studentGrades?.map((student, index) => (
                  <tr className="table-color" key={index}>
                    <td className="align-middle aluno-cell gap-2 px-2">
                      <Avatar name={student.studentName} round={true} size="35" color="#5b5fc7" maxInitials={2} textSizeRatio={2.9} />
                      {student.studentName}
                    </td>
                    {student?.evaluationGrade?.map((value: any, index: any) => (
                      <td
                        key={"td-" + index + "-" + student.studentOfficeId}
                        className="cellNote-color"
                      >
                        <input
                          id={"text-" + index + "-" + student.studentOfficeId}
                          disabled={true}
                          value={
                            evaluationGradeIdSelected === value.id &&
                              value.id != null
                              ? newNoteEvaluation
                              : value.grade != null
                                ? value.grade
                                : ""
                          }
                          className="form-control"
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {loading && (
            <div className="table-responsive">
              <Loader />
            </div>
          )}
        </div>
      )}
    </GradesStyles>
  );
}
export default Grades;
